<template>
  <div :data-is-hidden="!isActive" class="p-pro-detail__stored-contents">
    <div
      v-if="content"
      class="p-pro-detail__stored-content"
      :class="{
        'p-pro-detail__stored-content--hidden': !isActive
      }"
      :style="{
        height: isActive ? contentHeight : 0
      }">
      <div
        :class="{ 'u-mb24': type !== 'video' }"
        v-for="(item, index) in content"
        :key="index">
        <component :is="contentComponent" :content="item" />
      </div>
    </div>

    <Button
      v-if="!isActive"
      class="p-pro-detail__stored-content-more"
      style-type="secondary"
      fluid
      @click="onClickMoreButton">
      {{ buttonLabel }}
    </Button>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';

export default defineComponent({
  components: {
    ProDetailVideo: () => import('@/components/pro/ProDetailVideo.vue'),
    ProDetailOriginalCoffee: () =>
      import('@/components/pro/ProDetailOriginalCoffee.vue')
  },

  props: {
    type: {
      type: String,
      default: 'event'
    },

    content: {
      type: Array,
      default: null
    },

    buttonLabel: {
      type: String,
      default: 'もっと見る'
    }
  },

  setup: (props, context) => {
    const sleep = (ms = 250) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const isActive = ref(true);
    const contentHeight = ref(undefined);

    const onClickMoreButton = async () => {
      isActive.value = true;
      await sleep();
      contentHeight.value = 'auto';
    };

    const storeContent = async () => {
      await sleep();
      await setContentHeight();
      await sleep();
      isActive.value = false;
    };

    const setContentHeight = () => {
      contentHeight.value =
        document.querySelector('.p-pro-detail__stored-content')?.clientHeight +
        'px';
    };

    const contentComponent = computed(() => {
      let component = '';
      switch (props.type) {
        case 'video':
          component = 'ProDetailVideo';
          break;
        case 'product':
          component = 'ProDetailOriginalCoffee';
          break;
        case 'event':
          component = 'EventCard';
          break;
      }
      return component;
    });

    onMounted(async () => {
      await storeContent();
      context.root.$_loading_stop();
    });

    return {
      isActive,
      contentHeight,
      contentComponent,
      onClickMoreButton
    };
  }
});
</script>
