var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-pro-detail__stored-contents",
      attrs: { "data-is-hidden": !_vm.isActive },
    },
    [
      _vm.content
        ? _c(
            "div",
            {
              staticClass: "p-pro-detail__stored-content",
              class: {
                "p-pro-detail__stored-content--hidden": !_vm.isActive,
              },
              style: {
                height: _vm.isActive ? _vm.contentHeight : 0,
              },
            },
            _vm._l(_vm.content, function (item, index) {
              return _c(
                "div",
                { key: index, class: { "u-mb24": _vm.type !== "video" } },
                [
                  _c(_vm.contentComponent, {
                    tag: "component",
                    attrs: { content: item },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.isActive
        ? _c(
            "Button",
            {
              staticClass: "p-pro-detail__stored-content-more",
              attrs: { "style-type": "secondary", fluid: "" },
              on: { click: _vm.onClickMoreButton },
            },
            [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }